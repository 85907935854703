/* global botDetect */
import './vendor/bot-detector';

document.addEventListener('DOMContentLoaded', function (event) {
  botDetect.onUser(function () {
    const mailcryptSelector = document.querySelectorAll('.mailcrypt');
    for (let i = 0; i < mailcryptSelector.length; i += 1) {
      let html = mailcryptSelector[i].innerHTML.replaceAll('~', '@');
      let href = mailcryptSelector[i].getAttribute('href').replaceAll('~', '@');

      mailcryptSelector[i].setAttribute('href', href);
      mailcryptSelector[i].innerHTML = html;
    }

    const phoneNumberSelector = document.querySelectorAll('.my-phone-number');
    for (let i = 0; i < phoneNumberSelector.length; i += 1) {
      phoneNumberSelector[i].addEventListener('click', function () {
        let phoneFirst = this.getAttribute('data-first');
        let phoneMiddle = this.getAttribute('data-middle');
        let phoneLast = this.getAttribute('data-last');
        this.innerHTML = phoneFirst + phoneMiddle + phoneLast;
      });
    }
  });
});
